.background--primary {
    .newsletter__button {
        background: var(--theme-cta);
        color: var(--color-white);
    }
}

.background--tertiary {
    .newsletter {
        color: var(--theme-dark);

        .headlines__headline {
            color: var(--theme-primary);
        }
    }
}
