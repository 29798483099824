// Custom Properties in haus-hobby
:root {
    // Colors
    /////////////////////////////////////////////
    --theme-primary: #f7a717;
    --theme-secondary: #fee5c2;
    --theme-tertiary: #cde9ed;
    --theme-primary-opaque: rgb(247, 167, 23, 0.05);

    --theme-attention: #00b0c0;
    --theme-cta: #00b0c0;
    --theme-cta-font-color: #fff;

    // CTA Colors
    --color-teaser-cta-box: var(--theme-tertiary);
    --color-teaser-text-cta-box: var(--theme-cta-font-color);

    // Button
    --bg-button-primary: var(--theme-primary);
    --color-button-tertiary: var(--color-white-true);
    --background-product-buy-button: var(--theme-primary);

    // Header
    --quicklink-button-color: var(--theme-primary);
    --quicklink-text-color: var(--theme-cta-font-color);

    // Headlines
    --theme-on-secondary-highlight: var(--theme-cta);
    --theme-on-secondary-headline: var(--theme-attention);
    --theme-on-secondary-subheadline: var(--theme-dark);
    --theme-on-tertiary-highlight: var(--theme-cta);
    --theme-on-tertiary-headline: var(--theme-attention);
    --theme-on-tertiary-subheadline: var(--theme-dark);
    --theme-on-default-highlight: var(--theme-cta);
    --theme-on-default-headline: var(--theme-primary);
    --theme-on-default-subheadline: var(--theme-dark);

    //PDP
    --pdp-color-buy-button: var(--theme-cta-font-color);
    --pdp-background-buy-button: var(--theme-primary);
    --pdp-background-caption: var(--theme-tertiary);

    // Newsletter
    --newsletter-background-button: var(--theme-primary);

    // Navigation
    --main-navigation-grid-column-width-desktop: 151px 35% auto 160px;
    --main-logo-wrapper-max-width-desktop: 151px;
    --main-logo-wrapper-min-height-mobile: 20px;
    --main-logo-max-height-mobile: 42px;

    // Hero Slider
    --hero-teaser-slider-cta-color: var(--theme-dark);
    --hero-teaser-slider-subheadline-color: var(--theme-dark);
    --hero-teaser-button-text-color: var(--theme-cta-font-color);
}
